import {
  ClockCircleOutlined,
  CloseCircleFilled,
  EditOutlined,
  FileZipOutlined,
  MailOutlined,
  PaperClipOutlined,
  PhoneOutlined,
  ProfileOutlined,
  SaveOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";

import {
  Avatar,
  Button as Btn,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  MenuProps,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import BulkMessageModal from "src/components/bulk-message-modal/BulkMesssageModal";
import Button from "src/components/button";
import ListingSidebar from "src/components/listing-sidebar";
import { AddScheduleMessageModal } from "src/components/listing-sidebar/addScheduleMessageModal";
import { TextifyModal } from "src/components/modal";
import { PAGINATION, PhoneNumberRegex, maxSize } from "src/constants/common";
import { formattedTime, returnElipsesText } from "src/constants/functions";
import { getConversationByIdData } from "src/store/selectors/features/conversationByid";
import { getConversationUser } from "src/store/selectors/features/conversationUser";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import { getMessageLoadingState } from "src/store/selectors/features/message";
import { getTemplateData } from "src/store/selectors/features/template";
import { uploadFilesLoading } from "src/store/selectors/features/uploadFiles";
import RequestAppAction from "src/store/slices/appActions";
import { clearConversationById } from "src/store/slices/features/conversationsById";
import { setConversationUser } from "src/store/slices/features/currentUserConversation";
import Colors from "src/themes/colors";
import { AddTemplateModal } from "../settings/templates/addTemplateModal";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import "./converstions.scss";
import { firstName } from "src/constants/add-variable";
import { replaceContentVariables } from "src/components/modal-button-container/add-variable/utils";

const { Header, Sider, Content } = Layout;
const { Text, Title } = Typography;

interface modalProps {
  openModal: (text: string) => void;
  closeModal: () => void;
}

let tempUser: any = null;
let tempDid: null | number;

const Converstion = () => {
  const { width } = useWindowDimensions();

  const [inputValue, setInputValue] = useState<string>("");
  const user: any = useSelector(getConversationUser);
  const [messages, setMessages] = useState<any>(null);
  const scrollRef: any = useRef(null);
  const dispatch = useDispatch();
  const editContactModalRef = useRef<modalProps>(null);
  const [scrollFetch, setScrollFetch] = useState(false);
  const [pagination, setPagination] = useState(PAGINATION);
  const did = useSelector(getCurrentDidId);
  const templates: any = useSelector(getTemplateData);
  const [state, setState] = useState("");
  const getMessages: any = useSelector(getConversationByIdData);
  const sendingMessage = useSelector(getMessageLoadingState);
  const [form] = useForm();
  const isUploading = useSelector(uploadFilesLoading);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const uploadFileRef: any = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState<any>([]);
  const scheduleMessageModalRef = useRef<modalProps>(null);
  const templateModalRef = useRef<modalProps>(null);
  const [units, setUnits] = useState<number>(0);
  const [characters, setCharacters] = useState<number>(0);
  const [variableList, setVariableList] = useState<any>([]);
  const isLoading = useSelector(getMessageLoadingState);

  const openModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (scrollFetch && user) {
      const handler = setTimeout(() => {
        dispatch(
          RequestAppAction.fetchConversationsById({
            id: user?.id,
            meta: pagination,
            cbSuccess: (data: any) => {
              let arr = [...data?.data?.items];
              setMessages((pre: any) => ({
                ...pre,
                items: [...messages?.items, ...arr],
              }));

              setPagination((pre: any) => ({
                ...pre,
                page: pagination.page + 1,
              }));

              setScrollFetch(false);
            },
            cbFailure: (e: string) => {
              setScrollFetch(false);
              notification.error({ message: e });
            },
          })
        );
      }, 300);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [scrollFetch]);

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const isNearTop = clientHeight - scrollHeight;

      if (-scrollTop + 10 > -isNearTop) {
        if (pagination.page <= getMessages?.meta?.totalPages) {
          setScrollFetch(true);
        }
      }
    }
  };

  useEffect(() => {
    if (user) {
      dispatch(
        RequestAppAction.getUser({
          id: user?.id,
        })
      );
    }
  }, [user]);

  useEffect(() => {
    if (getMessages && !scrollFetch) {
      setMessages({ ...getMessages, items: [...getMessages?.items] });
    }
  }, [getMessages, scrollRef.current]);

  useEffect(() => {
    if (!getMessages) {
      setMessages(null);
    }
  }, [getMessages]);

  const fetchNewData = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
    dispatch(
      RequestAppAction.fetchConversationsById({
        id: user?.id,
        meta: PAGINATION,
        cbSuccess: () => {
          setPagination((pre: any) => ({
            ...pre,
            page: PAGINATION.page + 1,
          }));
        },
        cbFailure: (e: string) => {
          notification.error({ message: e });
        },
      })
    );
  };

  useEffect(() => {
    if (user) {
      fetchNewData();
      setInputValue("");
    }
  }, [user]);

  useEffect(() => {
    const listInnerElement: any = scrollRef?.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, [scrollRef.current?.scrollHeight, getMessages]);

  useEffect(() => {
    return () => {
      setPagination((pre: any) => ({
        ...pre,
        page: PAGINATION.page + 1,
      }));
      dispatch(setConversationUser(null));
    };
  }, []);

  const sendNewMessage = () => {
    let data: any = {
      content: replaceContentVariables(inputValue, variableList),
      units: units,
      variableList: variableList,
    };

    if (attachments.length > 0) {
      data["fileLocations"] = attachments;
    }

    dispatch(
      RequestAppAction.sendMessageRequest({
        didId: did,
        id: user?.id,
        data: data,
        cbSuccess: (res: any) => {
          setInputValue("");
          setAttachments([]);
          setMessages((pre: any) => ({
            ...pre,
            items: [res.data, ...messages?.items],
          }));
          dispatch(
            RequestAppAction.fetchConversations({
              id: did,
              meta: PAGINATION,
              cbFailure: (e: string) => {
                notification.error({ message: e });
              },
            })
          );
        },
        cbFailure: (e: string) => {
          setAttachments([]);
          notification.error({ message: e });
        },
      })
    );
  };

  const items: MenuProps["items"] = templates?.items?.map(
    (template: { content: any; title: string }, index: any) => ({
      key: String(index),
      label: template.title,
      content: template?.content,
    })
  );

  items?.push({ key: "Add New Template", label: "Add New Template" });

  useEffect(() => {
    const variables = [];
    variables.push(firstName);
    setVariableList(variables);
  }, []);

  useEffect(() => {
    const charactersLength = inputValue.length;
    const totalUnits = Math.floor(charactersLength / 160);
    const chr = charactersLength - (totalUnits || 0) * 160;

    if (charactersLength === 0 && attachments.length === 0) {
      setUnits(0);
    } else if (charactersLength > 0) {
      let val = totalUnits + 1 + attachments?.length * 3;
      setUnits(val);
    } else {
      let val = attachments?.length * 3;
      setUnits(val);
    }

    setCharacters(chr);
  }, [inputValue, attachments]);

  const handleMenuItemClick = (item: any) => {
    if (item.key === "Add New Template") {
      add();
    } else {
      setInputValue(item.content);
    }
  };

  const menu = () => (
    <Menu>
      {items?.map((menuItem: any, index: number) => (
        <Menu.Item onClick={() => handleMenuItemClick(menuItem)} key={index}>
          {menuItem.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const fetchTemplateData = () => {
    dispatch(
      RequestAppAction.fetchTemplate({
        id: did,
        meta: PAGINATION,
        cbFailure: (e: string) => {
          notification.error({ message: e });
        },
      })
    );
  };

  useEffect(() => {
    if (did) {
      fetchTemplateData();
      setMessages(null);
    }
  }, [did]);

  const add = () => {
    form.resetFields();
    setTimeout(() => {
      templateModalRef.current?.openModal(t("modalHeading.createNewTemplate"));
    }, 100);
  };
  const addMessage = () => {
    form.resetFields();
    setTimeout(() => {
      if (user) {
        scheduleMessageModalRef.current?.openModal(
          `Create New Scheduled Message for ${
            user?.firstName || user?.phoneNumber
          }`
        );
      }
    }, 100);
  };

  useEffect(() => {
    return () => {
      dispatch(clearConversationById());
    };
  }, []);

  tempDid = did;

  const socketUpdateList = (res: any) => {
    if (res?.contactId === tempUser?.id || res?.senderDid === tempDid) {
      if (tempUser?.id) {
        dispatch(
          RequestAppAction.fetchConversationsById({
            id: tempUser?.id,
            meta: PAGINATION,
            cbSuccess: () => {
              setPagination((pre: any) => ({
                ...pre,
                page: PAGINATION.page + 1,
              }));
            },
            cbFailure: (e: string) => {
              notification.error({ message: e });
            },
          })
        );
      }

      dispatch(
        RequestAppAction.fetchConversations({
          id: tempDid,
          meta: PAGINATION,
          cbSuccess: () => {
            setPagination((pre: any) => ({
              ...pre,
              page: PAGINATION.page + 1,
            }));
          },
          cbFailure: (e: string) => {
            notification.error({ message: e });
          },
        })
      );
    }
  };

  const onFinishEditContact = (val: any) => {
    const param = {
      firstName: val?.firstName?.trim(),
      lastName: val?.lastName?.trim(),
      phoneNumber: val?.phoneNumber?.trim()?.replace(/[\s-]/g, ""),
      email: val?.email?.trim(),
      companyName: val?.companyName?.trim(),
    };

    dispatch(
      RequestAppAction.editUser({
        id: user?.id,
        data: param,
        cbSuccess: (res: any) => {
          dispatch(setConversationUser(res?.data));
          notification.success({ message: t("notification.success") });
          setTimeout(() => {
            editContactModalRef.current?.closeModal();
          }, 200);
          dispatch(
            RequestAppAction.fetchConversations({
              id: did,
              meta: PAGINATION,

              cbFailure: (e: string) => {
                notification.error({ message: e });
              },
            })
          );
        },
        cbFailure: (e: string) => {
          notification.error({ message: e });
        },
      })
    );
  };

  useEffect(() => {
    if (state) {
      const handler = setTimeout(() => {
        if (!PhoneNumberRegex.test(state)) {
          form.setFields([
            {
              name: "phoneNumber",
              errors: [t("notification.numberMustBeValid")],
            },
          ]);
        }
      }, 300);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [state]);

  const onSave = async (values: any, id: any) => {
    try {
      await form.validateFields();
      const data = {
        content: values.content,
        title: values.title,
      };
      dispatch(
        RequestAppAction.postTemplate({
          data: data,
          id: id,
          cbSuccess: () => {
            notification.success({
              message: t("notification.saveTemplateSuccess"),
            });
            dispatch(
              RequestAppAction.fetchTemplate({
                id: did,
                meta: PAGINATION,
                cbFailure: (e: string) => {
                  notification.error({ message: e });
                },
              })
            );
            templateModalRef.current?.closeModal();
          },
          cbFailure: (e: string) => {
            notification.error({ message: e });
          },
        })
      );
    } catch (errorInfo: any) {
      notification.error({ message: errorInfo });
    }
  };

  const upload = (e: any) => {
    const formData = new FormData();
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > maxSize) {
        // Handle error: File size exceeds 5MB
        notification.error({
          message: t("common.fileSizeError", { name: file.name }),
        });

        return;
      }
    }

    for (const file of files) {
      formData.append("files", file);
    }

    dispatch(
      RequestAppAction.uploadFiles({
        data: formData,
        cbSuccess: (res: any) => {
          uploadFileRef.current.value = "";
          setAttachments((pre: any) => [...pre, ...res?.data]);
        },
        cbFailure: (e: string) => {
          uploadFileRef.current.value = "";
          notification.error({ message: e });
        },
      })
    );
  };

  useEffect(() => {
    setAttachments([]);
  }, [user]);

  const convertMsgLink = (msg: string): string => {
    if (msg) {
      const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
      const urlMatch = urlRegex.exec(msg);
      if (urlMatch) {
        const url = urlMatch[0];
        const beforeUrl = msg.slice(0, urlMatch.index);
        const afterUrl = msg.slice(urlMatch.index + url.length);
        return convertMsgLink(beforeUrl) + `<a href="${url}" target="_blank">${url}</a>` + convertMsgLink(afterUrl);
      } else {
        const emailRegex = /(?<!<a[^>]*>)(?<!:\/\/)([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)(?!<\/a>)/gi;
        return msg.replace(emailRegex, '<a href="mailto:$1">$1</a>');
      }
    }
    return '';
  };

  return (
    <Layout
      className="conversation-page"
      style={{ background: Colors.reciverBg }}
    >
      <Sider
        // breakpoint="md"
        // collapsedWidth="0"
        width={width ? (width > 992 ? 450 : width > 768 ? 350 : width > 576 ? 300 : 250) : 450}
        style={{ background: Colors.BgListing }}
      >
        <ListingSidebar
          templateModalRef={templateModalRef}
          openModalFav={openModal}
          socketUpdateList={socketUpdateList}
          getUserFun={(val) => {
            tempUser = val;
          }}
        />
      </Sider>
      <Layout className="converstion-content">
        <Header className="bg-primary pl-5 pr-5">
          <Row>
            <Col
              span={20}
              className="d-flex align-items-center justify-content-start"
            >
              <div style={{ paddingRight: "2rem" }}>
                <Avatar
                  size={40}
                  icon={
                    user?.firstName ? (
                      user?.firstName?.charAt(0).toUpperCase()
                    ) : (
                      <UserOutlined />
                    )
                  }
                  style={{
                    background: Colors.IconBackground,
                    color: Colors.IconColor,
                  }}
                />
              </div>
              <div className="subheader-user-info">
                <Title className="font-size-16 text-white text-start">
                  {user?.firstName || user?.phoneNumber}{" "}
                  {user?.firstName && user?.lastName}
                  {user?.companyName && <> {`(${user?.companyName})`}</>}
                  <br />
                  {user?.firstName && (
                    <span className="font-size-10 font-weight-300">
                      {`${user?.phoneNumber}`}
                    </span>
                  )}
                </Title>
              </div>
            </Col>
            <Col
              span={4}
              className="conversation-btns text-right align-items-center d-flex"
            >
              <Btn
                onClick={() => {
                  if (user) {
                    form.setFieldsValue({
                      firstName: user?.firstName,
                      lastName: user?.lastName,
                      phoneNumber: user?.phoneNumber,
                      email: user?.email,
                      companyName: user?.companyName,
                    });
                    editContactModalRef.current?.openModal(
                      t("heading.editContact")
                    );
                  }
                }}
                icon={<EditOutlined />}
                shape="circle"
                style={{ background: Colors.white }}
              />

              <Btn
                onClick={() => {
                  if (user?.id) {
                    dispatch(
                      RequestAppAction.downloadConversation({
                        id: user?.id,
                        cbSuccess: (blob: any) => {
                          const url = window.URL.createObjectURL(
                            new Blob([blob.data])
                          );
                          const link = document.createElement("a");
                          link.href = url;
                          const firstName =
                            user?.firstName || user?.phoneNumber;
                          link.setAttribute(
                            "download",
                            `Export_${
                              firstName + (user?.lastName ? user?.lastName : "")
                            }_${new Date().getTime()}.csv`
                          );

                          document.body.appendChild(link);

                          link.click();
                          if (link.parentNode) {
                            link.parentNode.removeChild(link);
                          }
                        },
                        cbFailure: (e: string) => {
                          notification.error({ message: e });
                        },
                      })
                    );
                  }
                }}
                icon={<SaveOutlined />}
                shape="circle"
              />
            </Col>
          </Row>
        </Header>

        <input
          ref={uploadFileRef}
          onChange={(e) => {
            const value = e.target.value.toLowerCase();

            if (
              value.endsWith(".png") ||
              value.endsWith(".jpg") ||
              value.endsWith(".svg") ||
              value.endsWith(".webp") ||
              value.endsWith(".jpeg") ||
              value.endsWith(".jpe") ||
              value.endsWith(".jif") ||
              value.endsWith(".jfif") ||
              value.endsWith(".jfi") ||
              value.endsWith(".svgz")
            ) {
              upload(e);
            } else {
              notification.error({ message: t("common.fileNotSupported") });
            }
          }}
          style={{ display: "none" }}
          type="file"
          multiple
        />

        <Content className="conversation-message">
          <Spin
            style={{ height: '100%', width: '100%' }}
            spinning={isUploading || (sendingMessage && attachments?.length > 0)}
          >
            <div ref={scrollRef} className="message-box pt-3">
              {messages &&
                messages?.items?.map((message: any, key: number) => (
                  <div
                    key={key}
                    style={{
                      padding: "0.5rem 3rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: message.userId ? "end" : "start",
                    }}
                  >
                    <div
                      style={{
                        background: message.userId
                          ? Colors.senderBg
                          : Colors.reciverBg,
                      }}
                      className="message"
                    >
                      <Row style={{ display: "flex" }}>
                        {message?.fileLocations &&
                          message?.fileLocations?.map(
                            (i: string, index: number) => {
                              if (
                                i?.endsWith(".png") ||
                                i?.endsWith(".jpg") ||
                                i?.endsWith(".svg") ||
                                i?.endsWith(".webp") ||
                                i?.endsWith(".jpeg") ||
                                i?.endsWith(".jpe") ||
                                i?.endsWith(".jif") ||
                                i?.endsWith(".jfif") ||
                                i?.endsWith(".jfi") ||
                                i?.endsWith(".svgz")
                              ) {
                                return (
                                  <div key={index} style={{ margin: "0.1rem" }}>
                                    <Col span={2}>
                                      <img
                                        key={Math.random()}
                                        onClick={() => {
                                          window.open(i, "_blank");
                                        }}
                                        style={{
                                          height: "5rem",
                                          cursor: "pointer",
                                          maxWidth: "10rem",
                                          objectFit: "contain",
                                        }}
                                        src={i}
                                      />
                                    </Col>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    onClick={() => {
                                      window.open(i, "_blank");
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Col span={2}>
                                      <FileZipOutlined
                                        style={{
                                          fontSize: "5rem",
                                          color: Colors.Gray,
                                        }}
                                      />
                                    </Col>
                                  </div>
                                );
                              }
                            }
                          )}
                      </Row>

                      <p
                        className="message-text"
                        dangerouslySetInnerHTML={{ __html: convertMsgLink(message?.content) }}
                      ></p>

                      <p className="message-meta">
                        <span
                          style={{ marginRight: "0px", marginTop: "0.2rem" }}
                        >
                          {message?.createdAt?.split("T")[0]}{" "}
                          {message?.createdAt &&
                            formattedTime(message?.createdAt)}{" "}
                        </span>
                      </p>
                      <p className="message-meta">
                        {message?.userId ? message?.username : ""}
                      </p>
                    </div>
                  </div>
                ))
              }
            </div>

            {user?.isBlocked && (
              <Row style={{ background: Colors.Secondary }}>
                <Space className="w-100 d-flex align-items-center justify-content-center">
                  <Text style={{ textAlign: "center", color: Colors.white }}>
                    {t("common.userBlocked")}
                  </Text>
                </Space>
              </Row>
            )}
          </Spin>
        </Content>

        <Row className="message-footer">
          <Col span={24}>
            <Col
              style={{
                display: "flex",
                background: "transparent",
                overflowX: "auto",
              }}
              span={24}
            >
              {attachments.map((i: string, ind: number) => (
                <span
                  style={{
                    position: "relative",
                    marginRight: "0.5rem",
                    marginTop: "0.5rem",
                    zIndex: 1,
                  }}
                >
                  <Tag
                    onClick={() => {
                      window.open(i, "_blank");
                    }}
                    style={{
                      background: Colors.white,
                      borderRadius: 4,
                    }}
                  >
                    <Text
                      key={ind}
                      className="font-size-14"
                      style={{
                        display: "flex",
                        cursor: "pointer",
                      }}
                    >
                      {returnElipsesText(i)}
                    </Text>
                  </Tag>
                  <div
                    style={{
                      position: "absolute",
                      top: -8,
                      right: 12,
                    }}
                  >
                    <CloseCircleFilled
                      onClick={() => {
                        const arr = attachments;
                        setAttachments(
                          arr.filter((item: any) => item !== i)
                        );
                      }}
                      style={{
                        color: Colors.red,
                        position: "absolute",
                      }}
                    />
                  </div>
                </span>
              ))}
            </Col>
          </Col>
          <Col span={17} className="d-flex-col align-items-center">
            <Row
              style={{ width: "100%" }}
              className={`counter-text ${width > 310 ? "d-flex" : "d-flex-column align-items-start"} font-size-14`}
            >
              <div>
                {t("sideBar.chars")} {characters}/160
              </div>
              <div className="pl-3">
                {t("sideBar.msgUnits")} {units}/10
              </div>
            </Row>
            <Row className="d-flex align-items-center">
              <TextArea
                onKeyDown={(e) => {
                  if (!user) return;
                  if (units > 10) return;
                  if (e.key === "Enter" && !e.shiftKey) e.preventDefault();
                  if (
                    inputValue.trim().length === 0 &&
                    attachments.length === 0 &&
                    !isLoading
                  )
                    return;

                  if (!isLoading && e.key === "Enter" && !e.shiftKey) {
                    sendNewMessage();
                  }
                }}
                style={{
                  resize: "none",
                  background: "white",
                  paddingRight: "3.4rem",
                }}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder={t("placeholder.enterToSendMessage")}
              />
              {units > 10 ? (
                <Tooltip
                  trigger={"hover"}
                  color="red"
                  title={t("message.unitError")}
                >
                  <Btn
                    disabled={true}
                    type="text"
                    icon={
                      <SendOutlined
                        style={{
                          color: Colors.TitleGray,
                          fontSize: "1.6rem",
                        }}
                      />
                    }
                    style={{
                      marginLeft: "-3.2rem",
                      border: "none",
                      background: "none",
                      boxShadow: "none",
                    }}
                  />
                </Tooltip>
              ) : (
                <>
                  <Btn
                    disabled={
                      (inputValue.trim().length < 1 &&
                        attachments.length < 1) ||
                      isLoading
                    }
                    onClick={() => {
                      if (user) {
                        if (
                          inputValue.trim().length > 0 ||
                          attachments.length > 0
                        ) {
                          sendNewMessage();
                        }
                      }
                    }}
                    type="text"
                    icon={
                      isLoading ? (
                        <Spin />
                      ) : (
                        <SendOutlined
                          style={{
                            color:
                              inputValue.trim().length > 0 ||
                              attachments.length > 0
                                ? Colors.DarkCharcoal
                                : Colors.TitleGray,
                            fontSize: "1.6rem",
                          }}
                        />
                      )
                    }
                    style={{
                      marginLeft: "-3.2rem",
                      border: "none",
                      background: "none",
                      boxShadow: "none",
                    }}
                  />
                </>
              )}
            </Row>
          </Col>
          <Col
            span={6}
            className="send-msg-btns d-flex align-items-center justify-content-evenly"
          >
            <Btn
              onClick={addMessage}
              style={{ background: Colors.white }}
              className="btn-hover send-msg-btn"
              icon={
                <Tooltip title={t("tootlTip.ScheduleMsg")}>
                  <ClockCircleOutlined />
                </Tooltip>
              }
            />
            <Btn
              style={{ background: Colors.white }}
              onClick={() => uploadFileRef.current.click()}
              className="send-msg-btn"
              icon={
                <Tooltip title={t("tootlTip.attachFile")}>
                  <PaperClipOutlined />
                </Tooltip>
              }
            />
            <Dropdown dropdownRender={menu} placement="topRight" arrow>
              <Btn
                style={{ background: Colors.white }}
                className="send-msg-btn"
                icon={<ProfileOutlined />}
              />
            </Dropdown>
          </Col>
        </Row>
      </Layout>

      <TextifyModal ref={editContactModalRef}>
        <div style={{ paddingTop: 25, paddingBottom: 15 }}>
          <Form onFinish={onFinishEditContact} name="userForm" form={form}>
            <Form.Item
              rules={[{ required: true, message: t("fields.nameError") }]}
              name={"firstName"}
              initialValue={user?.firstName}
            >
              <Input
                placeholder="Enter Name"
                prefix={<UserOutlined className="site-form-item-icon" />}
                suffix={
                  <CloseCircleFilled
                    onClick={() => {
                      form.setFieldsValue({ firstName: "" });
                    }}
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                }
              />
            </Form.Item>
            <Form.Item name={"lastName"} initialValue={user?.lastName}>
              <Input
                placeholder="Enter Last Name"
                prefix={<UserOutlined className="site-form-item-icon" />}
                suffix={
                  <CloseCircleFilled
                    onClick={() => {
                      form.setFieldsValue({ lastName: "" });
                    }}
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                }
              />
            </Form.Item>
            <Form.Item
              name={"phoneNumber"}
              rules={[{ required: true, message: t("fields.numberError") }]}
              initialValue={user?.phoneNumber}
            >
              <Input
                onChange={(e) => {
                  setState(e.target.value);
                }}
                onKeyDown={(e) => {
                  const key = e.key;
                  if (
                    !/[0-9]/.test(key) &&
                    key !== "ArrowLeft" &&
                    key !== "ArrowRight" &&
                    key !== "+" &&
                    key !== "Backspace"
                  ) {
                    e.preventDefault();
                  }
                }}
                placeholder="Phone Number"
                prefix={<PhoneOutlined className="site-form-item-icon" />}
                suffix={
                  <CloseCircleFilled
                    onClick={() => {
                      form.setFieldsValue({ phoneNumber: "" });
                    }}
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                }
              />
            </Form.Item>
            <Form.Item name={"email"} initialValue={user?.email}>
              <Input
                placeholder="Email"
                prefix={<MailOutlined className="site-form-item-icon" />}
                suffix={
                  <CloseCircleFilled
                    onClick={() => {
                      form.setFieldsValue({ email: "" });
                    }}
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                }
              />
            </Form.Item>
            <Form.Item name={"companyName"} initialValue={user?.companyName}>
              <Input
                placeholder="Company Name"
                suffix={
                  <CloseCircleFilled
                    onClick={() => {
                      form.setFieldsValue({ companyName: "" });
                    }}
                    style={{ color: "rgba(0,0,0,.45)" }}
                  />
                }
              />
            </Form.Item>
            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button buttonType="submit" title={t("button.save")} />
              <div style={{ marginRight: "1rem" }}>
                <Button
                  btnClass="outlined_btn"
                  onBtnClick={() => {
                    editContactModalRef.current?.closeModal();
                  }}
                  title={t("button.cancel")}
                />
              </div>
            </div>
          </Form>
        </div>
      </TextifyModal>
      <AddTemplateModal
        isEdit={false}
        ref={templateModalRef}
        dId={did}
        form={form}
        onSave={onSave}
        onUpdate={() => {}}
      />
      <AddScheduleMessageModal
        isEdit={false}
        modalRef={scheduleMessageModalRef}
        contactId={user?.id}
        form={form}
      />
      <BulkMessageModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        loading={loading}
        setLoading={setLoading}
      />
    </Layout>
  );
};

export default Converstion;
